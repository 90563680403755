// static structured json ld

export const organizationData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://infermedica.com/',
  sameAs: [
    'https://www.linkedin.com/company/infermedica/',
    'https://www.youtube.com/user/infermedica',
    'https://www.facebook.com/Infermedica/',
    'https://twitter.com/Infermedica',
    'https://www.instagram.com/infermedica/',
  ],
  logo: 'https://a.storyblok.com/f/120667/1533x512/e3c2730f75/infermedica-logo-color.png',
  name: 'Infermedica',
  description:
    'Infermedica is a market-leading AI-powered member engagement tool for payers, health insurers, and governments health programs, providing clinically validated navigation to guide members towards the most appropriate and cost-effective care.',
  email: 'contact@infermedica.com',
  address: 'Wrocław, Poland',
  contactPoint: {
    '@type': 'ContactPoint',
    email: 'contact@infermedica.com',
  },
  numberOfEmployees: {
    '@type': 'QuantitativeValue',
    minValue: 200,
    maxValue: 300,
  },
  foundingDate: '2012-06-01',
  vatID: 'PL8971782877',
}
